<template>
    <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
      <CContainer>
        <CRow class="mt-2 font-sm">
          <CCol style="min-width:500px">
            <CCardGroup>
              <CCard>
                <CCardBody>
  
  <!--                <BInputGroup prepend="패킷" class="mb-1">
                    <BFormSelect id="selPacket"
                                 v-model="selectedPacket"
                                 :options="hexOpts"
                                 @input="packet.Payload=selectedPacket.replace(/\s/g, '')"
                    />
                  </BInputGroup>-->
  
                  <BFormSelect v-model="packet.mid"
                               style="background-color:#2f303a"
                               :options="machineOpts"
                               @input="onSelectMachine"
                  />
  
                  <BInputGroup prepend="기능" class="mb-1">
                    <BFormSelect id="functionCode"
                                 v-model="packet.fcd"
                                 :options="fnOpts"
                    />
                  </BInputGroup>
  
  
  
  
  <!--                <BFormSelect v-model="packet.fcd"
                               :options="codeOpts"
                               @input="onSelectFuncCode"
                  />-->
  
                  <BInputGroup prepend="오일높이(mm)"  class="mb-1">
                    <BFormInput id="oh" type="number" v-model="packet.oil"/>
                  </BInputGroup>
  
                  <BInputGroup prepend="급유차오일초기높이"  class="mb-1">
                    <BFormInput id="ofv" type="number" v-model="packet.ofv"/>
                  </BInputGroup>
  
                  <BInputGroup prepend="수분높이(mm)" class="mb-1">
                    <BFormInput id="wh" type="number"
                                 v-model="packet.wtr"
                    />
                  </BInputGroup>
  
                  <BInputGroup prepend="기종" class="mb-1">
                    <BFormSelect id="eqcd"
                                 v-model="packet.eqcd"
                                 :options="eqOpts"
                    />
                  </BInputGroup>
  
                  <BInputGroup prepend="기번" class="mb-1">
                    <BFormInput id="eqid" v-model="packet.eqid"/>
                  </BInputGroup>
  
  
                  <BInputGroup prepend="군번" class="mb-1">
                    <BFormInput id="ano" type="text" v-model="packet.ano" />
                  </BInputGroup>
  
                  <BInputGroup prepend="위도/경도" class="mb-1">
                    <BFormInput id="lat" type="text" v-model="packet.gps"/>
                  </BInputGroup>
  
  
                  <BInputGroup prepend="온도" class="mb-1">
                    <BFormInput type="number" v-model="packet.tm1"/>
                    <BFormInput type="number" v-model="packet.tm2"/>
                    <BFormInput type="number" v-model="packet.tm3"/>
                    <BFormInput type="number" v-model="packet.tm4"/>
                    <BFormInput type="number" v-model="packet.tm5"/>
                    <BFormInput type="number" v-model="packet.tm6"/>
                  </BInputGroup>
  
  <!--                <BInputGroup prepend="HEX" class="mb-1">
                    <BFormInput id="hex"
                                v-model="packet.Payload"
  
                    />
                  </BInputGroup>-->
  
                  <BButtonGroup size="sm">
                    <BButton v-for="e in gpsData" :key="e.gps" @click="()=>{packet.gps=e.gps}">{{e.name}}</BButton>
                  </BButtonGroup>
  
                  <BButton variant="primary" @click="sendPacket" class="mt-2" block>전 송</BButton>

                  <BButton variant="primary" @click="delinterval" class="mt-2" block>종 료</BButton>

  
                  <p v-if="machine" class="font-weight-bold mt-2">
                    * TID: {{ machine.tank.name }} [{{ machine.tank.tid }}] <br/>
                    * 높이: {{machine.tank.tankHeight }} mm <br/>
                    * 길이: {{ machine.sensorLen }} mm <br/>
                    * GV: {{ machine.gainValue }}
                  </p>
  
  
                  Result: {{result}}
  
                </CCardBody>
  
              </CCard>
  
  
            </CCardGroup>
          </CCol>
          <CCol class="small p-2 border-dark" style="min-width:200px">
            <div v-if="machine">
            <BButton @click="getMachine(machine.mid)"> 장비정보 갱신 </BButton>
            <pre class="pre-scrollable text-white">
          {{machine}}
            </pre>
            <pre class="pre-scrollable text-white">
          {{machine.tank}}
            </pre>
            </div>
          </CCol>
  
        </CRow>
  
      </CContainer>
    </div>
  </template>
  
  <script>
  import {apiCall} from '@/common/utils';
  import {FuncCodeMap} from '@/common/constants';
  
  
  export default {
    name: 'PacketTest',
    props: {},
  
    data(){
      return {
        interval: null,
        mMap: {},
        fnoMap: {},
        selectedPacket: '',
        machineOpts: [],
        codeOpts: [],
        packet: {
          fno: 0,
          mid: null,
          fcd: '00',
          oil: 0,
          wtr: 0,
          tm1: 15,
          tm2: 14,
          tm3: 13,
          tm4: 12,
          tm5: 11,
          tm6: 10,
          gps: '36.711855,127.510529', // 위도,경도
          ano: '', // 인증번호
          eqcd: '01',
          eqid: '01',
          ofv: 0,  // 급유차 - 최초 유량값
          Payload: '',
          gain: 0,
          slen: 0,
  
        },
        machine: null,
        tank: null,
  
        fnCode: '00',
        fnOpts: [
          {value: '00', text: 'ATG-저장소'},
          {value: '01', text: 'ATG-난방유'},
          {value: '02', text: '급유차-급유'},
          {value: '03', text: '급유차-배유'},
          {value: '04', text: '급유차-인증'},
          {value: '05', text: '급유차-OFF'},
          {value: '06', text: '급유차-대기'},
        ],
        eqOpts: [
          { value:'01', text:'F-35'},
          { value:'02', text:'KF-16C'},
          { value:'03', text:'KF-16D'},
          { value:'04', text:'FA-50'},
          { value:'05', text:'KF-5E'},
          { value:'06', text:'KF-5F'},
          { value:'07', text:'F-15K'},
          { value:'08', text:'HH-60'},
          { value:'09', text:'AS-332'},
          { value:'10', text:'HH-32'},
          { value:'11', text:'B-412'},
          { value:'12', text:'HH-47'},
          { value:'13', text:'CH-47'},
          { value:'14', text:'기타(장비)'},
          { value:'15', text:'기타(외래기)'}
        ],
        gpsData: [
          {name:'1', gps:'36.706522,127.489387'},
          {name:'2', gps:'36.707399,127.490879'},
          {name:'3', gps:'36.706892,127.492284'},
          {name:'4', gps:'36.708182,127.492102'},
          {name:'5', gps:'36.707812,127.493829'},
          {name:'6', gps:'36.710332,127.495996'},
          {name:'7', gps:'36.710470,127.498099'},
          {name:'8', gps:'36.714572,127.502515'},
          {name:'9', gps:'36.715423,127.505980'},
          {name:'10', gps:'36.717900,127.503684'},
          {name:'11', gps:'36.711855,127.510529'},
          {name:'12', gps:'36.724276,127.524037'},
          {name:'13', gps:'36.724276,127.524037'},
          {name:'14', gps:'36.720268,127.514380'},
          {name:'15', gps:'36.718084,127.510583'},
          {name:'16', gps:'36.705813,127.492409'},
          {name:'17', gps:'36.708308,127.493589'},
  
        ],
        hexOpts: [
          {value: '', text:'패킷 선택' },
          {value: '02 0D 00 17 00 18 60 00 00 14 04 00 00 00 EB 00 00 00 00 00 00 00 00 00 00 00 00 00 00 A7 3A 03', text:'ATG-저장소' },
          {value: '02 0D 00 17 01 18 60 00 00 14 04 00 00 00 EB 00 00 00 00 00 00 00 00 00 00 00 00 00 00 A7 3A 03', text:'ATG-난방유' },
          {value: '02 0D 00 17 06 18 60 00 00 14 04 00 00 00 EB 00 00 00 00 00 00 00 00 00 00 00 00 00 00 A7 3A 03', text:'GPS 미입력대기' },
          {value: '02 29 00 17 06 18 60 00 00 14 04 00 00 00 EB 36 25 38 60 27 17 65 80 00 00 00 00 00 00 D9 FE 03', text:'GPS 구격납고 입력후 대기시' },
          {value: '02 39 00 17 06 18 60 01 7B 14 04 00 00 00 EB 36 25 38 60 27 17 65 80 00 00 00 00 00 00 65 28 03', text:'GPS , 기종(01),기번(123)' },
          {value: '02 47 00 17 02 18 60 01 7B 14 04 00 00 00 EB 36 25 38 60 27 17 65 80 14 04 00 00 00 00 87 A0 03', text:'급유시작시' },
          {value: '02 5A 00 17 02 18 60 01 7B 14 E2 00 00 00 EB 36 25 38 60 27 17 65 80 14 04 00 00 00 00 78 A4 03', text:'급유량 변동' },
          {value: '02 72 00 17 04 18 60 01 7B 14 E1 00 00 00 EB 00 00 00 01 23 45 67 89 14 04 00 00 00 00 D4 32 03', text:'군번(123456789)입력과 유량확인 버튼 누루면 5회 전송(Check Sum값은 틀림 , 프레임번호만 변동)' },
          {value: '02 7C 00 17 06 18 60 01 7B 14 E2 00 00 00 EB 36 25 38 60 27 17 65 80 14 04 00 00 00 00 9E 60 03', text:'Data전송후 다시 대기상태(GPS 표시)' },
          {value: '02 B0 00 17 06 18 60 00 00 14 E1 00 00 00 EB 36 25 38 60 27 17 65 80 00 00 00 00 00 00 3D 66 03', text:'초기화 버튼이 눌린후 상태' },
        ],
        result: '',
  
      }
    },
    async created(){
      this.getCodeOpts();
      await this.getMachineOpts();
  
  
  
    },
    beforeMount(){},
    mounted() {
    },
  
    computed: {
  
  
    },
  
    methods: {
  
      getCodeOpts(){
        this.codeOpts = [];
        Object.keys(FuncCodeMap).map(k=>{
          this.codeOpts.push({ value: k, text: FuncCodeMap[k] });
        });
        console.debug( 'codeOpts ------------>', this.codeOpts );
  
      },
      async getMachineOpts(){
        console.log("getMachineOptions---------");
        const {result} = await apiCall('get', `/api/machine/opts`);
        if( result ){
          this.machineOpts = [ {text: '장비 선택', value: null} ];
  
          for(let m of result){
            console.log(m);
            this.mMap[m.mid] = m;
            this.fnoMap[m.mid] = 0;
            this.machineOpts.push({text:`[${m.mid}] ${m.name}`, value: m.mid} );
          }
  
          console.log( 'machine options --->', this.machineOpts);
        }
  
      },
  
      async getMachine(mid){
        console.log("getMachineOptions---------");
        this.machine = null;
        const {result} = await apiCall('get', `/api/machine/mid/`+mid);
        if( result ){
          this.machine = result;
          console.log( 'machine  --->', this.machine);
        }
  
      },
  
  
  
      async sendPacket(){
        try {
        //   if(!this.packet.mid) return alert('장비를 선택하세요(필수입력)');
        //   this.result = '';
        //   const {mid} = this.packet;
        //   this.fnoMap[mid]++;
        //   if( this.fnoMap[mid] > 256) this.fnoMap[mid] = 1;
        //   this.packet.fno = this.fnoMap[mid];
        if (this.interval) {
          console.log("Interval already running");
          return;
        }
        console.log("Interval is running");
        
          this.interval = setInterval(async () => {
            try{
                if(!this.packet.mid) return alert('장비를 선택하세요(필수입력)');
                this.result = '';
                const {mid} = this.packet;
                this.fnoMap[mid]++;
                if( this.fnoMap[mid] > 256) this.fnoMap[mid] = 1;
                this.packet.fno = this.fnoMap[mid];

                const rs = await apiCall('post', `/iot/v1/packet`, this.packet);
                this.result = rs;
                console.log("sendPacket ---->", rs);
            } catch (err) {
                console.error(err);
                clearInterval(this.interval); 
            }
          }, 5000)
        //   const rs = await apiCall('post', `/iot/v1/packet`, this.packet );
        //   this.result = rs;
  
        //   console.log("sendPacket ---->", rs);
        }catch(err){
          console.error( err );
        }
  
      },

      async delinterval() {
          console.log("Current interval ID:", this.interval);
        if (this.interval) {
          clearInterval(this.interval);
          console.log("Interval 종료");
          this.interval = null;
        } else {
          console.log("interval 없음");
        }
      }, 
  
      onSelectMachine(){
        const {mid} = this.packet
        this.machine = this.mMap[mid];
        this.tank = this.machine.tank;
  
        // if(this.packet.Payload){
        //   this.packet.Payload = this.packet.Payload.replace(/1860/g, mid);
        // }
  
        this.packet.gain = this.machine.gainValue;
        this.packet.slen = this.machine.sensorLen;
  
        if(this.tank.tankShape==='4'){
          this.packet.fcd = '06';
        }
  
      },
  
      onSelectFuncCode(){
        // 0123456789 0123456789
        // 020D001701 186000001404000000EB0000000000000000000000000000A73A03
  
        let hex = this.packet.Payload
        hex = hex.substring(0,8) + this.packet.fcd + hex.substring(10);
        this.packet.Payload = hex;
  
      },
  
  
    }
  }
  </script>
  